<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-license</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="licencaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="10" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="licencaLocal.licenca"
                    :error-messages="compLicencaErrors"
                    label="Licenca"
                    required
                    outlined
                    @input="$v.licencaLocal.licenca.$touch()"
                    @blur="$v.licencaLocal.licenca.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" md="12" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="licencaLocal.descricao"
                    :error-messages="compDescricaoErrors"
                    label="Descrição"
                    required
                    outlined
                    @input="$v.licencaLocal.descricao.$touch()"
                    @blur="$v.licencaLocal.descricao.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="auto" md="12" align-self="center">
                  <v-autocomplete
                    hide-details="auto"
                    v-model="licencaLocal.idPerfil"
                    label="Perfil"
                    :items="compPerfisSelect"
                    item-text="perfil"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSubmit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const { mapState, mapActions } = createNamespacedHelpers("adminLicencas");

export default {
  mixins: [validationMixin],
  validations: {
    licencaLocal: {
      licenca: { required },
      descricao: { required },
    },
  },

  data: () => ({
    tituloForm: "",
    licencaLocal: {},
  }),
  computed: {
    ...mapState(["staLicencaSelecionado", "staPerfisSelect"]),

    compLicencaErrors() {
      const errors = [];
      if (!this.$v.licencaLocal.licenca.$dirty) return errors;
      !this.$v.licencaLocal.licenca.required && errors.push("Nome requerida.");
      return errors;
    },
    compDescricaoErrors() {
      const errors = [];
      if (!this.$v.licencaLocal.descricao.$dirty) return errors;
      !this.$v.licencaLocal.descricao.required &&
        errors.push("Descricão requerida");
      return errors;
    },

    compPerfisSelect() {
      return this.staPerfisSelect;
    },
  },
  watch: {
    staLicencaSelecionado(licencaNovo) {
      this.metSincronizar(licencaNovo);
    },
  },
  created() {
    this.metSincronizar(this.staLicencaSelecionado);
    this.staLicencaSelecionado
      ? (this.tituloForm = "Editar Licença")
      : (this.tituloForm = "Novo Licença");
    this.actSelectPerfis();
  },
  methods: {
    ...mapActions(["actCriarLicenca", "actEditarLicenca", "actSelectPerfis"]),

    async metSalvarLicenca() {
      this.licencaLocal.licenca = this.licencaLocal.licenca.trim();

      switch (this.staLicencaSelecionado) {
        case undefined:
          this.actCriarLicenca({ licenca: this.licencaLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Licença Criada!",
          });
          break;
        default:
          await this.actEditarLicenca({ licenca: this.licencaLocal });
          this.$root.snackbar.show({
            type: "success",
            message: "Licença Salva!",
          });
      }
    },

    metSincronizar(novoLicenca) {
      this.licencaLocal = Object.assign({}, novoLicenca || { id: null });
    },
    metSubmit() {
      this.$v.$touch();
      this.metSalvarLicenca();
    },
    metClear() {
      this.licencaLocal.licenca = this.staLicencaSelecionado.licenca;
      this.licencaLocal.descricao = this.staLicencaSelecionado.descricao;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
